import { TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_USER } from '../constants/AddUserDefault';
import REGIONS from '../constants/regions';
import TECHNOLOGY_TYPE from '../constants/technologyType';
import AddUserProps from '../interfaces/AddUserProps';
import { ApiHandler } from '../services/apiHandler';
import ErrorAlert from './ErrorAlert';
import LabelledInput from './LabelledInput';
import LabelLessDropdown from './LabelLessDropdown';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import SuccessAlert from './SuccessAlert';
import UserActions from './UserActions';
import './Users.css';
import '../pages/NetworkActivity.css';
import UsersTableHeader from './UsersTableHeader';
import WarningAlert from './WarningAlert';
import { getRole, getTechnology, getOrganisationId, getAllowedRoles } from '../services/helper';

interface UsersProps {
  processing: any;
}

const Users: React.FC<UsersProps> = ({ processing }) => {
  let navigate = useNavigate();
  let technologyTypeName = getTechnology() ?? '';
  let userRole = getRole() || '';
  const [users, setUsers] = useState<any>([]);
  const [newUser, setNewUser] = useState<AddUserProps>(DEFAULT_USER);
  const [modalVisible, toggleModalVisibility] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [currentUserPage, setCurrentUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [searchedUsers, setSearchedUsers] = useState<any[]>([]);
  const [showSearchedusers, setShowSearchedUsers] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [roleSelection, setRoleSelection] = useState<any[]>([]);
  const [technologyTypeTitle, setTechnologyTypeTitle] = useState(technologyTypeName);

  // useEffect(() => {
  //   console.log("roles: ", roleSelection);
  // }, [roleSelection]);
  useEffect(() => {
    roleValidation();
    getUsers();
  }, [currentUserPage, userRowsPerPage]);

  useEffect(() => {
    setNewUser(DEFAULT_USER);
    if (technologyTypeName === 'cbrs_5g' && userRole !== 'master admin') {
      setTechnologyTypeTitle('5G');
    } else if (technologyTypeName === 'wifi_6e' && userRole !== 'master admin') {
      setTechnologyTypeTitle('Wi-Fi 6E');
    } else {
      setTechnologyTypeTitle('Technology type *');
    }
  }, [modalVisible]);

  useEffect(() => {
    if (searchString.trim().length > 0) {
      getSearchedUsers(searchString);
      setShowSearchedUsers(true);
    } else {
      setSearchedUsers([]);
      setShowSearchedUsers(false);
      getUsers();
    }
  }, [searchString]);

  const catchApiError = (error: any) => {
    processing(false);
    if (error.doLogout === true) {
      setError({ message: 'Session Timed Out', alert: true });
      setTimeout(() => navigate('/login', { replace: true }), 2000);
    } else {
      setError({ message: error.data.message, alert: true });
    }
  };

  const getSearchedUsers = (searchTerm: string) => {
    ApiHandler({
      apiName: 'searchUsers',
      params: {},
      queryParams: { name: searchTerm },
      body: {},
    })
      .then((response: any) => {
        setSearchedUsers(response.data.users);
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  const getUsers = () => {
    processing(true);
    const queryParams: any = {
      page: currentUserPage,
      limit: userRowsPerPage,
    };
    ApiHandler({
      apiName: 'getUsers',
      body: {},
      params: {},
      queryParams: queryParams,
    })
      .then((response: any) => {
        processing(false);
        setUserCount(response.data.count);
        setUsers(response.data.users);
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  const handleCreateUserInput = (e: any) => {
    setNewUser((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const validateEmail = (email: string): boolean => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    }
    setWarning({ message: 'Invalid Email', alert: true });
    return false;
  };

  const roleValidation = () => {
    if (userRole === 'technology admin') {
      setRoleSelection(getAllowedRoles().slice(1));
    } else if (userRole === 'region admin') {
      setRoleSelection(getAllowedRoles().slice(2));
    } else {
      setRoleSelection(getAllowedRoles());
    }
  };

  const validateNewUser = (): boolean => {
    if (newUser.username.trim() === '') {
      setWarning({ message: 'Username is required', alert: true });
      return false;
    }
    if (newUser.email.trim() === '') {
      setWarning({ message: 'Email is required', alert: true });
      return false;
    }
    if (newUser.role.trim() === '') {
      setWarning({ message: 'Role is required', alert: true });
      return false;
    }
    if (getOrganisationId() !== 1 && newUser.technology_type.trim() === '') {
      setWarning({ message: 'Technology Type is required', alert: true });
      return false;
    }
    if (newUser.role !== 'Installer' && newUser.cpi_id.trim() === '') {
      setWarning({
        message: `CPI ID is required for ${newUser.role} role`,
        alert: true,
      });
      return false;
    }
    if (newUser.role !== 'Installer' && newUser.cpi_name.trim() === '') {
      setWarning({
        message: `CPI Name is required for ${newUser.role} role`,
        alert: true,
      });
      return false;
    }

    return validateEmail(newUser.email);
  };

  const addUser = () => {
    const isValid: boolean = validateNewUser();
    if (!isValid) return;
    if (userRole.toLowerCase() !== 'master admin')
      newUser.technology_type = TECHNOLOGY_TYPE.filter((tech) => tech.displayName === technologyTypeTitle)[0].dbName;
    if (getOrganisationId() === 1) newUser.technology_type = 'cbrs_5g';
    processing(true);
    ApiHandler({ apiName: 'createUser', body: newUser, params: {} })
      .then((response: any) => {
        processing(false);
        setSuccess({ message: response.data.message, alert: true });
        toggleModalVisibility(false);
        getUsers();
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  const getSelectedUser = (username: string) => {
    // setSearchString(username);
    processing(true);
    ApiHandler({
      apiName: 'searchUser',
      body: {},
      params: { username: username },
    })
      .then((response: any) => {
        processing(false);
        setShowSearchedUsers(false);
        const userFormat: any = [
          {
            cpi_id: response.data.cpi_id,
            email: response.data.email,
            full_name: response.data.full_name,
            role: response.data.role,
            username: response.data.username,
            technology_type: response.data.technology_type,
          },
        ];
        console.log(userFormat);
        setUsers(userFormat);
        setUserCount(1);
      })
      .catch((error: any) => {
        catchApiError(error);
      });
  };

  return (
    <div>
      <div className="app-card mt-4">
        <div className="row-wise space-between">
          <span className="app-card-heading">USERS</span>

          <button
            className="add-button"
            onClick={(e: any) => {
              e.preventDefault();
              toggleModalVisibility(!modalVisible);
            }}>
            <span className="material-icons-round font-14">add</span>
            ADD
          </button>
        </div>

        <div className="row-wise justify-content-between align-items-center mt-2 mb-2">
          <div>
            {/* Search Input */}
            <div className="form-floating input-group net-action-box d-flex align-items-center w-100 searchbar">
              <span className="network-icon material-icons-round ms-2">search</span>
              <input
                id="searchString"
                name="searchString"
                type={'text'}
                className={'net-search-input'}
                placeholder={'Search by name'}
                value={searchString}
                onChange={(e: any) => {
                  setSearchString(e.target.value);
                }}
              />
              {searchString !== '' ? (
                <span
                  className="network-icon material-icons-round me-2 m-auto"
                  onClick={() => {
                    setSearchString('');
                  }}>
                  close
                </span>
              ) : (
                <div className="network-icon me-4"></div>
              )}
            </div>

            {showSearchedusers && searchedUsers.length ? (
              <div className="user-search">
                {searchedUsers.map((user: any) => {
                  return (
                    <div
                      key={user.username}
                      className="col-wise pt-2 pb-2 user-search-row"
                      onClick={() => {
                        getSelectedUser(user.username);
                      }}>
                      <span>{user.full_name}</span>
                      <div className="row mt-1">
                        <div className="col row-wise">
                          <span className="user-search-title">Username: </span>
                          <span className="ms-1">{user.username}</span>
                        </div>
                        <div className="col row-wise">
                          <span className="user-search-title">Role: </span>
                          <span className="ms-1">{user.role}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <TablePagination
            component="div"
            count={userCount}
            page={currentUserPage}
            onPageChange={(e: any, newPage: any) => {
              setCurrentUserPage(newPage);
            }}
            rowsPerPage={userRowsPerPage}
            onRowsPerPageChange={(event: any) => {
              setUserRowsPerPage(event.target.value);
            }}
          />
        </div>

        <table className="w-100 d-table">
          <thead className="d-table w-100">
            <tr>
              <UsersTableHeader />
            </tr>
          </thead>
          {userCount === 0 ? (
            <div className="center mt-4">
              <span className="empty-data-label">~ Nothing to show ~</span>
            </div>
          ) : (
            <tbody className="user-tablebody">
              {users.map((user: any) => {
                return (
                  <tr key={user.username}>
                    <UserActions
                      user={user}
                      processing={processing}
                      refreshUsers={() => {
                        getUsers();
                      }}
                    />
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>

      <Modal show={modalVisible}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">Add User</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  toggleModalVisibility(!modalVisible);
                }}></button>
            </div>
            <div className="modal-body">
              <LabelledInput
                name="full_name"
                value={newUser.full_name}
                title={'First Name | Last Name'}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              <LabelledInput
                name="username"
                value={newUser.username}
                title={'Username *'}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              <LabelledInput
                name="email"
                value={newUser.email}
                title={'Email *'}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              <LabelLessDropdown
                name={'role'}
                className="mb-2"
                fullWidth
                title={'Role *'}
                options={roleSelection}
                value={newUser.role}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              {getOrganisationId() !== 1 ? (
                <LabelLessDropdown
                  disabled={userRole !== 'master admin'}
                  name={'technology_type'}
                  className="mb-2"
                  fullWidth
                  title={technologyTypeTitle}
                  options={TECHNOLOGY_TYPE.map((tech: any) => {
                    return tech.displayName;
                  })}
                  value={TECHNOLOGY_TYPE.filter((tech) => tech.dbName === newUser.technology_type)[0]?.displayName}
                  onChange={(e: any) => {
                    e.target.value = TECHNOLOGY_TYPE.filter((tech) => tech.displayName === e.target.value)[0].dbName;
                    handleCreateUserInput(e);
                  }}
                />
              ) : (
                <></>
              )}

              <LabelLessDropdown
                name={'region'}
                className="mb-2"
                fullWidth
                title={'Region'}
                options={REGIONS}
                value={newUser.region}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              <LabelledInput
                name="cpi_id"
                value={newUser.cpi_id}
                title={'CPI ID*'}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
              <LabelledInput
                name="cpi_name"
                value={newUser.cpi_name}
                title={'CPI Name*'}
                onChange={(e: any) => {
                  handleCreateUserInput(e);
                }}
              />
            </div>
            <div className="modal-footer">
              <PrimaryButton label="Create" disable={false} clicked={addUser} />
            </div>
          </div>
        </div>
      </Modal>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};

export default Users;

import React, { useState } from 'react';
import ImageResizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';
import { AssetObj, SectorObj, VendorObj } from '../interfaces/AssetProps';
import { ApiHandler } from '../services/apiHandler';
import AccordionInput from './AccordionInput';
import ErrorAlert from './ErrorAlert';
import SuccessAlert from './SuccessAlert';

interface InstallationAccordionProps {
  assetDetails: AssetObj | undefined;
  sectorDetails: SectorObj | undefined;
  vendorDetails: VendorObj | undefined;
  updateDetails: any;
  processing: any;
}

const InstallationAccordion: React.FC<InstallationAccordionProps> = ({ assetDetails, sectorDetails, vendorDetails, updateDetails, processing }) => {
  let navigate = useNavigate();
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });

  const imageType: any = {
    height_image: 'HEIGHT',
    azimuth_image: 'AZIMUTH',
    downtilt_image: 'DOWNTILT',
  };

  const uploadImageApi = (imageId: any, file: any, imageDataUrl: any) => {
    let requestBody: any = new FormData();
    requestBody.append('file', imageDataUrl);
    requestBody.append('type', imageType[imageId]);
    requestBody.append('ext', file[0]['path'].slice(file[0]['path'].lastIndexOf('.') + 1));
    requestBody.append('tracking_id', assetDetails?.tracking_id?.toString());
    ApiHandler({ apiName: 'upload', params: {}, body: requestBody })
      .then((response: any) => {
        processing(false);
        setSuccess({
          message: response.data.message,
          alert: true,
        });
        updateDetails(imageId, response.data.path, 'sector');
      })
      .catch((error: any) => {
        processing(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const uploadImage = async (file: any, imageId: string) => {
    processing(true);
    ImageResizer.imageFileResizer(
      file[0],
      800,
      800,
      'JPEG',
      100,
      0,
      (uri: any) => {
        uploadImageApi(imageId, file, uri);
      },
      'base64'
    );
  };

  const checkPhotoAdded = (image: any) => {
    return image ? 'done' : 'add_a_photo';
  };

  return (
    <div>
      <div className="row g-0">
        <div className="col">
          <AccordionInput title="Latitude" type="input" disabled value={assetDetails?.latitude?.toString() || ''} />
        </div>
        <div className="col">
          <AccordionInput title="Longitude" type="input" disabled value={assetDetails?.longitude?.toString() || ''} />
        </div>
      </div>
      <AccordionInput
        title="Height (m)"
        type="input"
        icon={checkPhotoAdded(sectorDetails?.height_image)}
        disabled
        onUpload={(file: any) => {
          uploadImage(file, 'height_image');
        }}
        value={sectorDetails?.height?.toString() || '0'}
      />
      <AccordionInput
        title="Height Type"
        type="dropdown"
        value={sectorDetails?.height_type?.toString()}
        options={['HEIGHT_TYPE_AGL', 'HEIGHT_TYPE_AMSL']}
        onChange={(e: any) => {
          updateDetails('height_type', e.target.value, 'sector');
        }}
      />
      <AccordionInput
        title="Horizontal Accuracy (m)"
        type="input"
        value={sectorDetails?.horizontal_accuracy || ''}
        onChange={(e: any) => {
          updateDetails('horizontal_accuracy', e.target.value, 'sector');
        }}
      />
      <AccordionInput
        title="Vertical Accuracy (m)"
        type="input"
        value={sectorDetails?.vertical_accuracy || ''}
        onChange={(e: any) => {
          updateDetails('vertical_accuracy', e.target.value, 'sector');
        }}
      />
      <AccordionInput
        title="HAAT(m)"
        type="input"
        value={sectorDetails?.haat?.toString() || ''}
        onChange={(e: any) => {
          updateDetails('haat', e.target.value, 'sector');
        }}
      />
      <AccordionInput
        title="Antenna Azimuth (Degrees)"
        type="input"
        icon={checkPhotoAdded(sectorDetails?.azimuth_image)}
        value={sectorDetails?.azimuth?.toString() || ''}
        onChange={(e: any) => {
          updateDetails('azimuth', parseInt(e.target.value), 'sector');
        }}
        onUpload={(file: any) => {
          uploadImage(file, 'azimuth_image');
        }}
      />
      <AccordionInput
        title="Down Tilt (Degrees)"
        type="input"
        icon={checkPhotoAdded(sectorDetails?.downtilt_image)}
        value={sectorDetails?.downtilt || ''}
        onChange={(e: any) => {
          updateDetails('downtilt', e.target.value, 'sector');
        }}
        onUpload={(file: any) => {
          uploadImage(file, 'downtilt_image');
        }}
      />
      <AccordionInput title="Antenna Gain (dBi)" type="input" disabled value={vendorDetails?.antenna_gain || ''} />
      <AccordionInput title="Antenna Beamwidth (Degrees)" type="input" disabled value={vendorDetails?.antenna_beamwidth || ''} />
      <AccordionInput title="Eirp Capability (dBm)" type="input" disabled value={vendorDetails?.eirp_capability || ''} />
      <AccordionInput title="Antenna Model" type="input" disabled value={vendorDetails?.antenna_model || ''} />
      <AccordionInput title="Antenna Pattern" type="input" disabled value={vendorDetails?.antenna_pattern || ''} />
      <AccordionInput
        title="Measurement Capability"
        type="dropdown"
        value={sectorDetails?.measurement_capability || ''}
        options={['MSRMT_WITHOUT_GRANT', 'MSRMT_WITH_GRANT', '']}
        onChange={(e: any) => {
          updateDetails('measurement_capability', e.target.value, 'sector');
        }}
      />
      <div>
        <input
          className="ms-3 me-2"
          type={'checkbox'}
          id={'indoorDeployment'}
          checked={sectorDetails?.deployment_type === 'indoor'}
          onChange={(e: any) => {
            console.log(e.target.checked);
            updateDetails('deployment_type', e.target.checked ? 'indoor' : 'outdoor', 'sector');
          }}
        />
        <label className="mt-3 mb-3 checkbox-label" htmlFor={'indoorDeployment'}>
          Indoor Deployment
        </label>
      </div>

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
    </div>
  );
};

export default InstallationAccordion;

import React, { useState } from 'react';
import CBSDAssets from '../components/CBSDAssets';
import CBSDAssetSearch from '../components/CBSDAssetSearch';
import CBSDAssetFilter from '../components/CBSDAssetFilter';
import Charts from './Charts';
import './NetworkActivity.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getOrganisationId } from '../services/helper';

interface NetworkActivityProps {
  takeToMap: any; // Function to take user to map.
}

const NetworkActivity: React.FC<NetworkActivityProps> = ({ takeToMap }) => {
  const [chartFilters, setChartFilters] = useState({});
  const [searchFilters, setSearchFilters] = useState({});
  const [assetFilters, setAssetFilters] = useState({});

  const updateChartFilters = (filters: any) => {
    setChartFilters(filters);
  };

  const updateSearchFilters = (filters: any) => {
    setSearchFilters(filters);
  };

  const updateAssetFilters = (filters: any) => {
    setSearchFilters({});
    setAssetFilters(filters);
  };

  return (
    <div className="backdrop col-wise overflow-auto">
      {/* Asset Chart view */}
      {getOrganisationId() !== 1 ? <Charts title={'Chart component'} chartFilters={updateChartFilters} /> : <></>}

      {/* Top bar for actions */}
      <div className="app-card mt-3">
        <div className="row-wise justify-content-between">
          {/* Search module */}
          <CBSDAssetSearch searchFilters={updateSearchFilters} />

          {/* Filters */}
          <CBSDAssetFilter chartFilters={chartFilters} searchFilters={searchFilters} assetFilters={updateAssetFilters} />
        </div>
      </div>

      {/* Asset List Table with Navigation and Pagination */}
      <CBSDAssets chartFilters={chartFilters} searchFilters={searchFilters} assetFilters={assetFilters} takeToMap={takeToMap} />
    </div>
  );
};

export default NetworkActivity;

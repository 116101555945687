// AUTHOR: Rahul Choudhary | Whirldata Inc.
// DATE: 22 Aug 2022
// DESCRIPTION:
// -- For users to login to the web-app.
// -- Hooks for username and password capture the values as and when they are entered,
//    loginClicked() method calls the <login> API,
//    gets the idToken in the response, stores in <storage> to be used in header for all other API calls.
// -- Once authenticated, /home page replaces this page in navigation stack.
// -- Below mentioned column ratios are from bootstrap v5 context.

import { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Logo from '../assets/logo.png';
import { ApiHandler } from '../services/apiHandler';
import jwtDecode from 'jwt-decode';
import Spinner from '../components/Spinner';
import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccessAlert';

const Login: FC = () => {
  let navigate = useNavigate(); // Hook for navigation used in loginClicked()

  // State Hooks
  const [isPasswordVisible, togglePasswordType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/home', { replace: true });
    }
  }, []);

  // Methods
  const loginClicked = (event: any) => {
    event.preventDefault();
    if (username.trim() !== '' && password.trim() !== '') {
      setLoading(true);
      ApiHandler({ apiName: 'login', params: {}, body: { username: username, password: password } })
        .then((response: any) => {
          const decoded: any = jwtDecode(response.data.access_token);
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("technology_type", decoded?.technology_type_name ?? "NO_TECH")
          setLoading(false);
          navigate('/home', { replace: true });
        })
        .catch((error: any) => {
          setLoading(false);
          setErrorMessage(error.data.message);
          setErrorAlert(true);
        });
    }
  };

  const forgotPasswordClicked = (e: any) => {
    e.preventDefault();
    if (email.trim() === '') return;
    setLoading(true);
    ApiHandler({ apiName: 'forgotPassword', params: {}, body: { email: email.trim().toLowerCase() } })
      .then((response: any) => {
        setLoading(false);
        setSuccessMessage('Email sent successfully.');
        setSuccessAlert(true);
        setEmailSent(true);
      })
      .catch((error: any) => {
        setLoading(false);
        setErrorMessage(error.data.message);
        setErrorAlert(true);
      });
  };

  // View
  return (
    <div>
      {/* Background overlay image */}
      <div className="background" />
      {/* Login Card */}
      <div className="vertical-center h-100 w-100" style={{ position: 'absolute', justifyContent: 'end' }}>
        {!forgotPasswordActive ? (
          <div className="login-card center" style={{ marginRight: '7.3vw' }}>
            <img className="logo" src={Logo} alt="c3-spectra logo"></img>

            <span className="action-title mb-3" style={{ marginTop: '5.5vh' }}>
              Welcome Back!
            </span>

            <span className="action-instructions" style={{ marginBottom: '3.9vh' }}>
              Please log in to your account
            </span>

            {/* Username input field */}
            <form className="w-100" onSubmit={loginClicked}>
              <div className="form-floating w-100" style={{ marginBottom: '1.3vh' }}>
                <input
                  className="form-control input-box input-text vertical-center"
                  placeholder="Enter your username"
                  id="usernameInput"
                  onInput={(e: any) => {
                    setUsername(e.target.value);
                  }}
                  value={username}
                ></input>
                <label className="input-label vertical-center" htmlFor="usernameInput">
                  Username
                </label>
              </div>

              {/* Password Input field with eye icon to show/hide divided in 10:2 columns of a row */}
              <div className="row gx-0 center input-with-icon w-100">
                <div className="col-10">
                  <div className="form-floating">
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      className="form-control input-password-box input-text border-0 vertical-center"
                      placeholder="Enter your password"
                      id="passwordInput"
                      onInput={(e: any) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                    ></input>
                    <label className="input-label vertical-center" htmlFor="passwordInput">
                      Password
                    </label>
                  </div>
                </div>

                <div
                  className="col-2 center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    togglePasswordType(!isPasswordVisible);
                  }}
                >
                  <span className="material-icons-round">{isPasswordVisible ? 'visibility' : 'visibility_off'}</span>
                </div>
              </div>

              {/* Forgot Password and Log in button in 6:6 columns of a row */}
              <div className="row-wise w-100 justify-content-between vertical-center mt-4 mb-4">
                <div className="row-wise">
                  <input className="me-2" type={'checkbox'} id="rememberMe" />
                  <label className="forgot-password-text" htmlFor="rememberMe">
                    Remember me
                  </label>
                </div>

                <span
                  className="interactible-text forgot-password-text"
                  onClick={() => {
                    setForgotPasswordActive(true);
                  }}
                >
                  Forgot Password?
                </span>
              </div>
              <button type="submit" className="w-100 center primary-action-button" disabled={username === '' || password === ''}>
                <span>Log in</span>
              </button>
            </form>

            {/* Agreement text */}
            <span className="agreement-text" style={{ marginTop: '3.9vh' }}>
              By Continuing, you agree to our
            </span>

            <div className="agreement-text">
              <a target={'_blank'} href="https://c3spectra.com/training/terms-conditions/" className="agreement-text text-decoration-underline me-1">
                {'Terms & Conditions'}
              </a>
              <span>and</span>
              <a target={'_blank'} href="https://c3spectra.com/training/privacy-policy/" className="agreement-text ms-1 text-decoration-underline">
                {'Privacy Policy'}
              </a>
            </div>
          </div>
        ) : (
          <div className="login-card center" style={{ marginRight: '7.3vw' }}>
            <img className="logo" src={Logo} alt="c3-spectra logo"></img>

            <span className="action-title mb-3" style={{ marginTop: '5.5vh' }}>
              Forgot Password
            </span>
            {!emailSent ? (
              <div className="col-wise">
                <span className="action-instructions" style={{ marginBottom: '3.9vh' }}>
                  Enter the email address associated with your account and we'll send instructions to reset your password.
                </span>

                <form className="w-100" onSubmit={forgotPasswordClicked}>
                  <div className="form-floating w-100" style={{ marginBottom: '1.3vh' }}>
                    <input
                      className="form-control input-box input-text vertical-center"
                      placeholder="Enter your email address"
                      id="emailInput"
                      type={'email'}
                      onInput={(e: any) => {
                        setEmail(e.target.value);
                      }}
                      autoComplete={'off'}
                      value={email}
                    ></input>
                    <label className="input-label vertical-center" htmlFor="usernameInput">
                      Email Address
                    </label>
                  </div>

                  <button type="submit" className="w-100 center primary-action-button" disabled={email === ''}>
                    <span>Send</span>
                  </button>
                </form>
              </div>
            ) : (
              <div className="email-confirmation mt-4 mb-4">
                <span>
                  Check your inbox for the next steps. If you don't receive an email, and it's not in your spam folder this could mean your account is
                  associated with a different address.
                </span>
              </div>
            )}

            <span
              className="interactible-text forgot-password-text mt-4"
              onClick={() => {
                setForgotPasswordActive(false);
                setEmail('');
                setEmailSent(false);
              }}
            >
              Log In instead?
            </span>
          </div>
        )}
      </div>

      <Spinner show={loading} />
      <ErrorAlert
        show={errorAlert}
        onDismiss={() => {
          setErrorAlert(false);
        }}
        message={errorMessage}
      />
      <SuccessAlert
        show={successAlert}
        onDismiss={() => {
          setSuccessAlert(false);
        }}
        message={successMessage}
      />
    </div>
  );
};

export default Login;

import React from 'react';
import './ImportDataModal.css';
import Modal from './Modal';
import { ValidationResults } from './ImportDataModal';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';

interface UploadErrorModalProps {
  show: boolean;
  close: () => void;
  validationResults: ValidationResults;
  onBrowse: () => void;
  onDownload: () => void;
}

const UploadErrorModal: React.FC<UploadErrorModalProps> = ({ show, close, validationResults, onBrowse, onDownload }) => {
  return (
    <Modal show={show}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{'IMPORT DATA'}</h5>

            <button
              type="button"
              className="btn-close"
              onClick={(e: any) => {
                e.preventDefault();
                close();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex w-100 flex-column align-items-center justify-content-center error-container">
              <span style={{ color: '#F44545' }} className="material-icons-round upload-icon">
                error_outline
              </span>
              <span className="error-description mt-2">{'Errors were found during data validation.'}</span>
              <div className="d-flex flex-row w-100 justify-content-between align-items-center count-details mt-4">
                <span>{'Number of UIDs uploaded:'}</span>
                <span>{validationResults.total_uploaded ?? 0}</span>
              </div>
              <div className="d-flex flex-row w-100 justify-content-between align-items-center count-details mt-1">
                <span>{'Number of UIDs verified:'}</span>
                <span>{validationResults.total_valid ?? 0}</span>
              </div>
              <div className="d-flex flex-row w-100 mb-2">
                <span className="error-description mt-4">{'Errors'}</span>
              </div>
              {validationResults.issue_counts?.map((issue, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100 justify-content-between align-items-center count-details mt-1">
                    <span>{issue.issue}</span>
                    <span>{issue.count}</span>
                  </div>
                );
              })}
              <span style={{ color: '#0073FF' }} className="material-icons-round mt-4">
                info
              </span>
              <span style={{ textAlign: 'center' }} className="count-details mt-2">
                {'Please download the Excel file for the errors and reasoning, then try again.'}
              </span>
            </div>

            <div className="d-flex flex-row w-100 gap-3 mt-4">
              <SecondaryButton
                label="Browse"
                clicked={() => {
                  onBrowse();
                }}
                className="w-50"
              />
              <PrimaryButton
                label="Download Excel"
                clicked={() => {
                  onDownload();
                }}
                className="w-50"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadErrorModal;

import React from 'react';
import { getOrganisationId } from '../services/helper';

const CBSDAssetTableHeader: React.FC = () => {
  return (
    <div className="row table-header-card vertical-center mt-3">
      <div className="col">
        <span>{'Tracking ID / UID'} </span>
      </div>
      <div className="col">
        <span>CBRS Node Name</span>
      </div>
      <div className="col">
        <span>Site ID</span>
      </div>
      <div className="col">
        <span>Mounting Type</span>
      </div>
      <div className="col">
        <span>CBSD Category</span>
      </div>
      <div className="col">
        <span>Installer</span>
      </div>
      <div className="col">
        <span>Last Modified</span>
      </div>
      <div className="col">
        <span>Age (in Days)</span>
      </div>
    </div>
  );
};

export default CBSDAssetTableHeader;

import React from 'react';
import './ImportDataModal.css';
import Modal from './Modal';
import Dropzone from 'react-dropzone';

interface UploadModalProps {
  show: boolean;
  close: () => void;
  onUpload: (files: any) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ show, close, onUpload }) => {
  return (
    <Modal show={show}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{'IMPORT DATA'}</h5>

            <div className="d-flex flex-row align-items-center gap-3">
              <a
                href={`${process.env.PUBLIC_URL}/assets/spectrapro-upload-template.xlsx`}
                download={'spectrapro-upload-template.xlsx'}
                className="template"
              >
                Download Template
              </a>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  close();
                }}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => {
                onUpload(acceptedFiles);
              }}
              accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 'application/vnd.ms-excel': ['.xlsx'] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="w-100">
                  <div
                    {...getRootProps({
                      className: 'file-dropzone',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="">
                      <span className="material-icons-round upload-icon">cloud_upload</span>
                      <p>Drag 'n' drop some files here, or click to select files</p>
                      <em>(Only .xlsx files are allowed)</em>
                    </div>
                  </div>
                  <div></div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;

import React, { useState } from "react";
import { ICbsdVendorCardsProps } from "./ICbsdVendorCardsProps";
import Vendor from "../Vendor";
import { exists, getOrganisationId } from "../../services/helper";
const CbsdVendorCards: React.FC<ICbsdVendorCardsProps> = (props) => {
  const [activeTab, setActiveTab] = useState<"cbsd" | "cpe">("cbsd");

  return (
    <div>
      {/* Vendor Type selection tabs */}
      <ul className="nav mt-3">
        <li
          id="cbsd-vendors"
          onClick={() => {
            setActiveTab("cbsd");
          }}
          className={`${activeTab === "cbsd" ? "active" : ""}`}
        >
          {"CBSD"}
        </li>
        {getOrganisationId() != 1 ? (
          <li
            id="cpe-vendors"
            onClick={() => {
              setActiveTab("cpe");
            }}
            className={`${activeTab === "cpe" ? "active" : ""}`}
          >
            {"CPE"}
          </li>
        ) : (
          <></>
        )}
      </ul>

      {activeTab === "cbsd" ? (
        <div className="row gx-0">
          <div className="col-4 pe-1">
            <Vendor
              title="Aerial"
              type="cbrs_5g"
              processing={props.processing}
              selectedVendorId={props.selectedAerialVendorId}
              options={props.aerialOptions}
              refresh={props.refresh}
              onChange={props.onAerialChange}
            />
          </div>

          <div className="col-4 ps-1 pe-1">
            <Vendor
              title="ROE"
              type="cbrs_5g"
              selectedVendorId={props.selectedRoeVendorId}
              processing={props.processing}
              options={props.roeOptions}
              refresh={props.refresh}
              onChange={props.onRoeChange}
            />
          </div>

          <div className="col-4 ps-1">
            <Vendor
              title="SMB"
              type="cbrs_5g"
              selectedVendorId={props.selectedSmbVendorId}
              processing={props.processing}
              options={props.smbOptions}
              refresh={props.refresh}
              onChange={props.onSmbChange}
            />
          </div>
        </div>
      ) : activeTab === "cpe" ? (
        <div className="row gx-0">
          <div className="col-4 pe-1">
            <Vendor
              title="CPE"
              type="cpe_cbsd"
              processing={props.processing}
              selectedVendorId={props.selectedCpeVendorId}
              options={props.cpeOptions}
              refresh={props.refresh}
              onChange={props.onCpeChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CbsdVendorCards;

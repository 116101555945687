import React from "react";
import { exists, getOrganisationId } from "../services/helper";
const UsersTableHeader: React.FC = () => {
  return (
    <div className="row table-header-card vertical-center">
      <div className="col-2">
        <span>Full Name</span>
      </div>
      <div className="col-2">
        <span>Username</span>
      </div>
      <div className={`${getOrganisationId() !== 1 ? "col-2" : "col-3"}`}>
        <span>Email</span>
      </div>
      {getOrganisationId() !== 1 ? (
        <div className="col-1">
          <span>Technology Type</span>
        </div>
      ) : (
        <></>
      )}
      <div className="col-2">
        <span>Role</span>
      </div>
      <div className="col-2">
        <span>CPI ID</span>
      </div>
      <div className="col-1"></div>
    </div>
  );
};

export default UsersTableHeader;

import React from 'react';
import './ImportDataModal.css';
import Modal from './Modal';

interface UploadSuccessModalProps {
  show: boolean;
  close: () => void;
  totalCount: number;
}

const UploadSuccessModal: React.FC<UploadSuccessModalProps> = ({ show, close, totalCount }) => {
  return (
    <Modal show={show}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{'Asset Details'}</h5>

            <button
              type="button"
              className="btn-close"
              onClick={(e: any) => {
                e.preventDefault();
                close();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center justify-content-center gap-3 px-4 mb-3">
              <span style={{ color: '#008000' }} className="material-icons-outlined upload-icon mb-2">
                verified
              </span>
              <span className="success-message">{'The assets are imported successfully'}</span>
              <span className="success-count">{`Number of UIDs imported: ${totalCount}`}</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadSuccessModal;

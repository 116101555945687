import React, { useEffect, useState } from 'react';
import { CBSDStatuses } from '../constants/GlobalConstants';
import { exists } from '../services/helper';

interface AssetSummaryProps {
  searchedView: any;
  summary: any;
  refreshAssets: any;
}

const CBSDAssetsNav: React.FC<AssetSummaryProps> = ({ searchedView, summary, refreshAssets }) => {
  const [viewType, setViewType] = useState(CBSDStatuses.FIELD_TECH.key);

  useEffect(() => {
    refreshAssets(viewType);
  }, [viewType]);

  const isFieldTech = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.FIELD_TECH.key : viewType === CBSDStatuses.FIELD_TECH.key;
  };

  const isCloud = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.CLOUD.key : viewType === CBSDStatuses.CLOUD.key;
  };

  const isSAS = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.SAS.key : viewType === CBSDStatuses.SAS.key;
  };

  const isRMA = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.RMA_REQUESTED.key : viewType === CBSDStatuses.RMA_REQUESTED.key;
  };

  const isPreloaded = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.PRELOADED.key : viewType === CBSDStatuses.PRELOADED.key;
  };

  return (
    <div className="d-flex">
      <div
        onClick={() => {
          setViewType(CBSDStatuses.PRELOADED.key);
        }}
        className={'row-wise ' + (isPreloaded() ? 'selected-view' : 'unselected-view')}
      >
        <span>{CBSDStatuses.PRELOADED.displayText}</span>
        <div className="count-bubble">
          <span>{summary.preloadedCount}</span>
        </div>
      </div>

      <span className="vertical"></span>
      <div
        onClick={() => {
          setViewType(CBSDStatuses.FIELD_TECH.key);
        }}
        className={'row-wise ' + (isFieldTech() ? 'selected-view' : 'unselected-view')}
      >
        <span>{CBSDStatuses.FIELD_TECH.displayText}</span>
        <div className="count-bubble">
          <span>{summary.fieldCount}</span>
        </div>
      </div>

      <span className="vertical"></span>
      <div
        onClick={() => {
          setViewType(CBSDStatuses.CLOUD.key);
        }}
        className={'row-wise ' + (isCloud() ? 'selected-view' : 'unselected-view')}
      >
        <span>{CBSDStatuses.CLOUD.displayText}</span>
        <div className="count-bubble">
          <span>{summary.cloudCount}</span>
        </div>
      </div>

      <span className="vertical"></span>
      <div
        onClick={() => {
          setViewType(CBSDStatuses.SAS.key);
        }}
        className={'row-wise ' + (isSAS() ? 'selected-view' : 'unselected-view')}
      >
        <span>{CBSDStatuses.SAS.displayText}</span>
        <div className="count-bubble">
          <span>{summary.sasCount}</span>
        </div>
      </div>

      <span className="vertical"></span>
      <div
        onClick={() => {
          setViewType(CBSDStatuses.RMA_REQUESTED.key);
        }}
        className={'row-wise ' + (isRMA() ? 'selected-view' : 'unselected-view')}
      >
        <span>{CBSDStatuses.RMA_REQUESTED.displayText}</span>
        <div className="count-bubble">
          <span>{summary.rmaCount}</span>
        </div>
      </div>
    </div>
  );
};

export default CBSDAssetsNav;

/* eslint-disable */

import React, { useEffect, useState } from "react";
import { IFloorActionsProps } from "./IFloorActionsProps";
import LabelledInput from "../LabelledInput";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import "./_flooractions.scss";
import { ApiHandler } from "../../services/apiHandler";
import Spinner from "../Spinner";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import { useNavigate } from "react-router-dom";
import LabelLessDropdown from "../LabelLessDropdown";
import FLOOR_TYPES from "../../constants/floorTypes";
import { ConfigProvider } from "antd";
// @ts-ignore
// import { Map, EditorImpl, Constants } from "mapbox-ui-components";
// import "mapbox-ui-components/index.css";

export const FloorActions: React.FC<IFloorActionsProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [floor, setFloor] = useState({
    building_id: "",
    floor_type: "",
    floor_number: "",
    description: "",
    image: undefined,
    floor_plan_image: undefined,
    floor_plan_geometry: undefined,
    base_from_ground: 0.0,
    ceiling_from_ground: 0.0,
    floor_base_thickness: 0.0,
    id: undefined,
  });
  const [building, setBuilding] = useState<any>();
  const [isMapOpen, setIsMapOpen] = useState(false);

  useEffect(() => {
    getBuilding();
  }, [props.buildingId]);

  useEffect(() => {
    if (props.floorActionType === "edit") {
      getFloor();
    }
  }, [props.floorActionType, props.floorId]);

  const getFloor = () => {
    setLoading(true);
    ApiHandler({ apiName: "getFloor", body: {}, params: { floor_id: props.floorId } })
      .then((res: any) => {
        setLoading(false);
        setFloor(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getBuilding = () => {
    setLoading(true);
    ApiHandler({ apiName: "getBuilding", body: {}, params: { building_id: props.buildingId } })
      .then((res: any) => {
        setLoading(false);
        setBuilding(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleInputChange = (e: any) => {
    setFloor((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const createFloor = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("building_id", props.buildingId);
    formData.append("floor_type", floor.floor_type);
    formData.append("floor_number", floor.floor_number);
    formData.append("description", floor.description);
    formData.append("base_from_ground", floor.base_from_ground.toString());
    formData.append("ceiling_from_ground", floor.ceiling_from_ground.toString());
    formData.append("floor_base_thickness", floor.floor_base_thickness.toString());
    if (floor.image !== undefined) {
      formData.append("image", floor.image);
    }
    if (floor.floor_plan_image !== undefined) {
      formData.append("floor_plan_image", floor.floor_plan_image);
    }
    if (floor.floor_plan_geometry !== undefined) {
      formData.append("floor_plan_geometry", JSON.stringify(floor.floor_plan_geometry));
    }
    ApiHandler({ apiName: "createFloor", body: formData, params: {} })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: "Floor Added Successfully",
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo("buildingDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const updateFloor = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("building_id", props.buildingId);
    formData.append("floor_type", floor.floor_type);
    formData.append("floor_number", floor.floor_number);
    formData.append("description", floor.description);
    formData.append("base_from_ground", floor.base_from_ground.toString());
    formData.append("ceiling_from_ground", floor.ceiling_from_ground.toString());
    formData.append("floor_base_thickness", floor.floor_base_thickness.toString());
    if (floor.id !== undefined) {
      formData.append("id", floor.id);
    }
    if (floor.image !== undefined) {
      formData.append("image", floor.image);
    }
    if (floor.floor_plan_image !== undefined) {
      formData.append("floor_plan_image", floor.floor_plan_image);
    }
    if (floor.floor_plan_geometry !== undefined) {
      formData.append("floor_plan_geometry", floor.floor_plan_geometry);
    }
    ApiHandler({ apiName: "updateFloor", body: formData, params: { floor_id: props.floorId } })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: "Floor Updated Successfully",
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo("buildingDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const floorPlanEditorClosed = (e: any) => {
    setIsMapOpen(false);
    if (e?.orphans && e?.orphans.length !== 0) {
      let geojson: any = { ...e?.orphans[0] };
      geojson.type = "FeatureCollection";
      setFloor((prevValues: any) => {
        return { ...prevValues, ["floor_plan_geometry"]: geojson };
      });
      props.setFloorGeoJSON(geojson);
    }
  };

  return (
    <div className="h-100 w-100 overflow-auto">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo("buildingDetails");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">{props.floorActionType === "add" ? "ADDING" : "EDITING"} FLOOR</span>
        </div>

        <LabelledInput title="Building Name" name="building_name" disabled={true} value={building?.name} className="mb-2 mt-3 ps-1 pe-3" />

        <form className="overflow-auto col-wise h-100 pe-3 ps-1 pb-1 mb-1 pt-2">
          <LabelLessDropdown
            className="mb-2"
            title="Floor Type *"
            options={FLOOR_TYPES}
            fullWidth
            name={"floor_type"}
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={floor.floor_type}
          />

          <LabelledInput
            title="Floor Number*"
            name="floor_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            type="number"
            value={floor.floor_number}
          />

          {/* Description */}
          <div className="form-floating w-100 mb-2">
            <textarea
              rows={5}
              id="description"
              className="form-control textbox-box input-text h-auto"
              placeholder={"Description"}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={floor.description}
            ></textarea>
            <label className="input-label" htmlFor="description">
              Description
            </label>
          </div>

          <LabelledInput
            title="Base from Ground (ft) *"
            className={""}
            type={"number"}
            value={floor?.base_from_ground}
            name="base_from_ground"
            placeholder="Enter Base from Ground height in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          <LabelledInput
            title="Ceiling from Ground (ft) *"
            className={""}
            type={"number"}
            value={floor?.ceiling_from_ground}
            name="ceiling_from_ground"
            placeholder="Enter Ceiling from Ground height in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          <LabelledInput
            title="Floor Base Thickness (ft) *"
            className={""}
            type={"number"}
            value={floor?.floor_base_thickness}
            name="floor_base_thickness"
            placeholder="Enter Floor Base Thickness in ft"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
          />

          {/* Draw Floor Plan */}
          <div className="draw-floor-plan-container mt-4">
            <span className="mb-4">Draw Floor Plan</span>
            <div className="d-flex align-items-start mb-4">
              <span className="material-icons-outlined draw-floor-plan-info">info</span>
              <p className="mx-2">Place the image of your floor plan on the map and make your own digital floor.</p>
            </div>

            {/* Floor Plan Geometry Creation */}
            <SecondaryButton
              className="mb-2 mt-2 w-100"
              label={![undefined, null].includes(floor.floor_plan_geometry) ? "Edit Floor Plan Geometry" : "Add Floor Plan Geometry"}
              clicked={() => {
                setIsMapOpen(!isMapOpen);
              }}
              disable={false}
            />
          </div>
        </form>

        <PrimaryButton
          className="mb-2 me-4 ms-1 mt-2"
          label={`${props.floorActionType === "add" ? "Create" : "Update"} floor`}
          clicked={() => {
            setConfirmAlert(true);
          }}
          disable={false}
        />
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Do you confirm ${props.floorActionType === "add" ? "creation" : "updation"} of the floor?`}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert(false);
        }}
        visible={confirmAlert}
        title={`Finish floor ${props.floorActionType === "add" ? "Creation" : "Updation"}`}
        primaryAction={() => {
          setConfirmAlert(false);
          if (props.floorActionType === "add") {
            createFloor();
          } else {
            updateFloor();
          }
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />

      <Spinner show={false} />

      {isMapOpen ? (
        <div
          id="floor-plan-editor"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 58,
            left: 0,
            zIndex: 9,
          }}
        >
          {/* <ConfigProvider
            theme={{
              token: {
                colorPrimary: Constants.PRIMARY_COLOR,
                colorPrimaryBg: Constants.BACKGROUND_COLOR, // "#2B3756"
                wireframe: false,
                colorInfo: Constants.PRIMARY_COLOR,
                colorSuccess: Constants.SUCCESS_COLOR,
                colorWarning: Constants.WARNING_COLOR,
                colorError: Constants.ERROR_COLOR,
                colorBgBase: Constants.PRIMARY_COLOR,
                colorTextBase: Constants.TEXT_COLOR,
              },
            }}
          >
            <Map.Provider>
              <EditorImpl
                onClose={(e: any) => {
                  floorPlanEditorClosed(e);
                }}
                tiles={"mapbox/satellite-v9"}
                data={floor?.floor_plan_geometry}
              />
            </Map.Provider>
          </ConfigProvider> */}
        </div>
      ) : null}
    </div>
  );
};

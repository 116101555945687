import axios from 'axios';
import jwtDecode from 'jwt-decode';
import apiConfig from './apiConfig.json';

const client = axios.create({
  // baseURL: process.env.REACT_APP_SPECTRA_PRO_API_URL, // development
  baseURL: `${window.location.origin}/api`, // Production
  // baseURL: `https://spectra-pro-v2.development.c3spectra.com/api`, // Local Production
  // baseURL: `https://hmno.spectra-dev.spectrum.net/api`, // Charter Dev
});

export const ApiHandler = ({ apiName, params, body, queryParams = {} }) => {
  return new Promise(async (resolve, reject) => {
    let headers = {
      Accept: 'application/json',
    };

    try {
      let [method, url] = '';
      try {
        method = apiConfig['api'][apiName]['method'];
        url = await setUrlParams(apiConfig['api'][apiName]['url'], params);
      } catch (error) {
        reject({
          doLogout: false,
          data: { message: error },
        });
      }

      headers['Content-Type'] = apiConfig['api'][apiName]['contentType'];

      if (apiConfig['exclude_authorization'].indexOf(apiName) < 0) {
        validateAccessToken()
          .then((response) => {
            headers['Authorization'] = `Bearer ${response.accessToken}`;
            selectApi(headers, url, body, method, queryParams)
              .then((response) => resolve(response))
              .catch((error) => reject(error));
          })
          .catch((response) => {
            if (response.doLogout) {
              reject({ doLogout: true });
            } else {
              reject({
                doLogout: false,
                data: { message: response.message },
              });
            }
          });
      } else {
        selectApi(headers, url, body, method, queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      }
    } catch (error) {
      reject({
        doLogout: false,
        data: { message: error },
      });
    }
  });
};

function selectApi(headers, url, body, method, queryParams) {
  return new Promise((resolve, reject) => {
    switch (method) {
      case 'GET':
        callGet(url, headers, queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case 'POST':
        callPost(url, body, headers, queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case 'PUT':
        callPut(url, body, headers)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case 'DELETE':
        callDelete(url, headers)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
    }
  });
}

function callGet(url, headers, queryParams) {
  return new Promise((resolve, reject) => {
    client
      .get(url, { headers: headers, params: queryParams })
      .then((response) => {
        resolve({
          doLogout: false,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject({
            doLogout: true,
          });
        } else {
          reject({
            doLogout: false,
            data: error.response.data,
          });
        }
      });
  });
}

function callPost(url, body, headers, queryParams) {
  return new Promise((resolve, reject) => {
    client
      .post(url, body, { headers: headers, params: queryParams })
      .then((response) => {
        resolve({
          doLogout: false,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject({
            doLogout: true,
          });
        } else {
          reject({
            doLogout: false,
            data: error.response.data,
          });
        }
      });
  });
}

function callPut(url, body, headers) {
  return new Promise((resolve, reject) => {
    client
      .put(url, body, { headers: headers })
      .then((response) => {
        resolve({
          doLogout: false,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject({
            doLogout: true,
          });
        } else {
          reject({
            doLogout: false,
            data: error.response.data,
          });
        }
      });
  });
}

function callDelete(url, headers) {
  return new Promise((resolve, reject) => {
    client
      .delete(url, { headers: headers })
      .then((response) => {
        resolve({
          doLogout: false,
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject({
            doLogout: true,
          });
        } else {
          reject({
            doLogout: false,
            data: error.response.data,
          });
        }
      });
  });
}

function validateAccessToken() {
  return new Promise((resolve, reject) => {
    try {
      if ([undefined, null, 'undefined', 'null', ''].includes(localStorage.getItem('access_token'))) {
        reject({
          doLogout: true,
        });
      } else {
        let accessToken = localStorage.getItem('access_token');
        isExpired(accessToken)
          .then((expired) => {
            if (!expired) {
              resolve({
                doLogout: false,
                accessToken: accessToken,
              });
            } else {
              validateRefreshToken()
                .then((response) => {
                  resolve({
                    doLogout: false,
                    accessToken: response.accessToken,
                  });
                })
                .catch(() => {
                  reject({
                    doLogout: true,
                  });
                });
            }
          })
          .catch((error) => {
            reject({
              doLogout: false,
              data: { message: error.message },
            });
          });
      }
    } catch (error) {
      reject({
        doLogout: false,
        data: { message: error },
      });
    }
  });
}

function validateRefreshToken() {
  return new Promise((resolve, reject) => {
    try {
      if ([undefined, null, 'undefined', 'null', ''].includes(localStorage.getItem('refresh_token'))) {
        reject({
          doLogout: true,
        });
      } else {
        let refreshToken = localStorage.getItem('refresh_token');
        isExpired(refreshToken)
          .then((expired) => {
            if (!expired) {
              let headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken}`,
              };
              callPost(apiConfig['api']['refresh']['url'], {}, headers)
                .then((response) => {
                  localStorage.setItem('access_token', response.data.access_token);
                  resolve({
                    doLogout: false,
                    accessToken: response.data.access_token,
                  });
                })
                .catch((error) => {
                  console.log('refresh_token', error);
                  reject({
                    doLogout: false,
                    data: error.data,
                  });
                });
            } else {
              reject({
                doLogout: true,
              });
            }
          })
          .catch((error) => {
            reject({
              doLogout: false,
              data: { message: error.message },
            });
          });
      }
    } catch (error) {
      reject({
        doLogout: false,
        data: { message: error },
      });
    }
  });
}

function isExpired(token) {
  return new Promise((resolve, reject) => {
    try {
      let decoded = jwtDecode(token);
      if (decoded.exp < new Date() / 1000) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject({
        doLogout: false,
        data: { message: error },
      });
    }
  });
}

function setUrlParams(url, params) {
  return new Promise((resolve, reject) => {
    try {
      for (let param in params) {
        let key = '{' + param + '}';
        do {
          url = url.replace(key, params[param]);
        } while (url.includes(key));
      }
      resolve(url);
    } catch (error) {
      reject({
        doLogout: false,
        data: { message: error },
      });
    }
  });
}

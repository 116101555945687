import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AerialIcon from '../assets/aerial.svg';
import RoeIcon from '../assets/roe.svg';
import sectorIcon from '../assets/sectorIcon.svg';
import sectorIconSelected from '../assets/sectorIconSelected.svg';
import { DEFAULT_ASSET, DEFAULT_SECTOR } from '../constants/AddAssetDefault';
import IAssetImageObj from '../constants/assetImageObject';
import MOUNTING_TYPES from '../constants/mountingTypes';
import AddAssetProps, { AddSectorProps } from '../interfaces/AddAssetProps';
import { ApiHandler } from '../services/apiHandler';
import { exists, getOrganisationId, getRegion } from '../services/helper';
import { CBSDMountingTypes } from './../constants/GlobalConstants';
import './Asset.css';
import AssetImagesModal from './AssetImagesModal/AssetImagesModal';
import ErrorAlert from './ErrorAlert';
import LabelLessDropdown from './LabelLessDropdown';
import LabelledInput from './LabelledInput';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import Spinner from './Spinner';
import SuccessAlert from './SuccessAlert';
import WarningAlert from './WarningAlert';
import ConfirmAlert from './confirmAlert';

interface AssetProps {
  goBack: any;
  position: { lat: number; lng: number };
  azimuthActivity: any;
  goToProfile: () => void;
  venuesOnMap: any[];
  setFloorGeoJSONUrl: (url: any) => void;
  unsetFloorGeoJSON: () => void;
  setVenueId: (venueId: any) => void;
  setBuildingId: (buildingId: any) => void;
  setFloorId: (floorId: any) => void;
}

const Asset: React.FC<AssetProps> = ({
  goBack,
  position,
  azimuthActivity,
  goToProfile,
  venuesOnMap,
  setVenueId,
  setBuildingId,
  setFloorId,
  setFloorGeoJSONUrl,
  unsetFloorGeoJSON,
}) => {
  const azimuthInterval = useRef<NodeJS.Timer>();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [defaultVendorId, setDefaultVendorId] = useState(0);
  const [mountingType, setMountingType] = useState('');
  const [azimuth, setAzimuth] = useState<string[]>([]);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [isAzimuthActive, activateAzimuth] = useState<boolean>(false);
  const [assetImages, setAssetImages] = useState<IAssetImageObj[]>([
    {
      type: 'RADIO_LABEL',
      path: '',
      label: 'Radio Label',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'OPERATIONAL_RF_SIGNAL_LEVEL',
      path: '',
      label: 'Operational RF Signal Level',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'OPERATIONAL_VOLTAGE_LEVEL',
      path: '',
      label: 'Operational Voltage Level',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'COAX_CABLE_CONNECTION_LIGHTS',
      path: '',
      label: 'Coax Cable Connection and Lights',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'DIRECTIONAL_COUPLER_RADIO_CONNECTION',
      path: '',
      label: 'Connection from Directional Coupler to Radio',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'INSTALLATION_STREET_VIEW',
      path: '',
      label: 'Installation Street View',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_1',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_2',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_3',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_4',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
  ]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [asset, setAsset] = useState<AddAssetProps>(DEFAULT_ASSET);
  const [vendorNotSelected, showVendorNotSelected] = useState(false);
  const [associatedVenue, setAssociatedVenue] = useState<any>(null);
  const [assetOnVenueAlert, setAssetOnVenueAlert] = useState(false);
  const [availableBuildings, setAvailableBuildings] = useState<any[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>(null);
  const [availableFloors, setAvailableFloors] = useState<any[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [directionality, setDirectionality] = useState<'Directional' | 'Omni' | ''>('');
  const [vendorType, setVendorType] = useState<'Airspan' | 'Nokia' | ''>('');

  useEffect(() => {
    return () => {
      stopAzimuthRotation();
      cancelAzimuth();
    };
  }, []);

  // useEffect(() => {
  //   console.log("Default image", ASSET_IMAGE_OBJECT);
  //   console.log("image", assetImages);
  // }, [assetImages]);

  // useEffect(() => {
  //   console.log("Default asset", DEFAULT_ASSET);
  //   console.log("Asset", asset);
  // }, [asset]);

  useEffect(() => {
    if (exists(mountingType)) {
      getDefaultVendorsInfo(mountingType);
    }
  }, [mountingType]);

  useEffect(() => {
    onChangeDirectionality(directionality, vendorType);
  }, [directionality, vendorType]);

  useEffect(() => {
    validateIsAssetOnVenue();
  }, [position]);

  useEffect(() => {
    setAssetOnVenueAlert(associatedVenue !== null);
  }, [associatedVenue]);

  useEffect(() => {
    if (selectedBuilding !== null) {
      listFloors();
    }
  }, [selectedBuilding]);

  const onChangeDirectionality = (_directionality: 'Directional' | 'Omni' | '', _vendorType: 'Airspan' | 'Nokia' | '') => {
    if (['Omni', ''].includes(_directionality)) return;
    if (exists(mountingType) && mountingType === CBSDMountingTypes.AERIAL) {
      setAzimuth(['0', '180']);
      return;
    }
    if (exists(mountingType) && mountingType === CBSDMountingTypes.ROE) {
      setAzimuth(['0']);
      return;
    }
  };

  const validateIsAssetOnVenue = () => {
    let contains: boolean = false;
    let tempVenue: any = null;
    for (let venue of venuesOnMap) {
      let doesContain: boolean = window.google.maps.geometry.poly.containsLocation(
        new window.google.maps.LatLng(position.lat, position.lng),
        new window.google.maps.Polygon({ paths: venue?.polygon })
      );
      if (doesContain) {
        contains = doesContain;
        tempVenue = venue;
      }
    }
    if (contains) {
      setAssociatedVenue(tempVenue);
    } else {
      setAssociatedVenue(null);
      setSelectedBuilding(null);
      setSelectedFloor(null);
      showFloorPlan(null);
      setVenueId('');
      setBuildingId('');
      setFloorId('');
      setAvailableBuildings([]);
      setAvailableFloors([]);
    }
  };

  const getDefaultVendorsInfo = (mountingType: string) => {
    setLoading(true);
    const requestParams: any = {
      mountingtype: mountingType,
    };
    ApiHandler({
      apiName: 'getDefaultVendorsInfo',
      body: {},
      params: requestParams,
    })
      .then((response: any) => {
        setLoading(false);
        setDefaultVendor(response.data.vendor_id);
        setDirectionality(response.data.directionality);
        setVendorType(response.data.vendor_type);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const setDefaultVendor = (vendorId: any) => {
    if (vendorId === null || vendorId === 0) {
      showVendorNotSelected(true);
      return;
    }
    setDefaultVendorId(vendorId);
  };

  const initiateImageUpload = () => {
    if (asset.tracking_id.trim() === '') {
      setWarning({
        alert: true,
        message: getOrganisationId() !== 1 || vendorType !== 'Nokia' ? 'Tracking ID cannot be empty.' : 'UID cannot be empty.',
      });
      return;
    }
    setShowImageModal(true);
  };

  const getSectors = () => {
    let sectorsHtml: any = [];
    let numSectors = 0;
    if (mountingType === CBSDMountingTypes.AERIAL && directionality === 'Directional') {
      numSectors = 2;
    } else if (mountingType === CBSDMountingTypes.ROE && directionality === 'Directional') {
      numSectors = 1;
    }
    for (let count = 0; count < numSectors; count++) {
      const element = (
        <div className="col-12 row-wise">
          <div className="col-6 pe-1">
            <LabelledInput title="Sector ID" disabled value={(count + 1).toString()} />
          </div>
          <div className="col-6 ps-1">
            <LabelledInput title="Azimuth" disabled value={azimuth[count]} />
          </div>
        </div>
      );
      sectorsHtml.push(element);
    }
    return sectorsHtml;
  };

  const getSectorIcons = () => {
    let totalIcons: number = 6;
    let iconList: any[] = [];
    if (mountingType === CBSDMountingTypes.SMB || mountingType === '' || directionality === 'Omni') {
      return iconList;
    }
    for (let index = 0; index < totalIcons; index++) {
      if ((mountingType === CBSDMountingTypes.AERIAL && index < 2) || (mountingType === CBSDMountingTypes.ROE && index < 1)) {
        const element = <img src={sectorIconSelected}></img>;
        iconList.push(element);
      } else {
        const element = <img src={sectorIcon}></img>;
        iconList.push(element);
      }
    }
    return iconList;
  };

  const validateRequest = () => {
    if (asset.tracking_id.trim() === '') {
      setWarning({
        alert: true,
        message: getOrganisationId() !== 1 || vendorType !== 'Nokia' ? 'Tracking ID cannot be empty.' : 'UID cannot be empty.',
      });
      return false;
    } else if (mountingType === '') {
      setWarning({ alert: true, message: 'Valid Mounting Type is required.' });
      return false;
    } else if (asset.serial_number.trim() === '') {
      setWarning({ alert: true, message: 'Valid Serial Number is required.' });
      return false;
    } else if (asset.height === 0 || isNaN(Number(asset.height))) {
      setWarning({ alert: true, message: 'Valid height is required.' });
      return false;
    } else if (defaultVendorId === 0) {
      showVendorNotSelected(true);
      return false;
    }
    return true;
  };

  const addAsset = () => {
    if (!validateRequest()) return;
    setLoading(true);
    calculateElevation()
      .then((response: any) => {
        let rcamsl = response.data.features[0].properties.elevation + parseFloat((parseFloat(asset.height.toString() || '0') / 3.281).toFixed(2));
        calculateHaat(rcamsl)
          .then((response: any) => {
            let haat = response.data.features[0].properties.haat_average.toFixed(2);
            let sectorList: AddSectorProps[] = [];
            if ([CBSDMountingTypes.AERIAL, CBSDMountingTypes.ROE].includes(mountingType) && directionality !== 'Omni') {
              azimuth.forEach((value: any, index: any) => {
                const sector: AddSectorProps = addSectorToRequest(value, index + 1, haat);
                const finalSector: any = { ...sector };
                sectorList.push(finalSector);
              });
            }
            // Adding a sector to the SMB to store values in DB but will not be treated as a sector in the functionalities
            if (mountingType === CBSDMountingTypes.SMB || directionality === 'Omni') {
              const sector: AddSectorProps = addSectorToRequest('0', 1, haat);
              const finalSector: any = { ...sector };
              sectorList.push(finalSector);
            }

            let uploadedImages: any = [];
            assetImages?.forEach((image: IAssetImageObj) => {
              if (image.uploaded) {
                uploadedImages.push({ type: image.type, path: image.path, latitude: image.latitude, longitude: image.longitude });
              }
            });
            let requestBody: AddAssetProps = asset;
            requestBody.latitude = parseFloat(position.lat.toFixed(6));
            requestBody.longitude = parseFloat(position.lng.toFixed(6));
            requestBody.region = getRegion();
            requestBody.images = uploadedImages;
            requestBody.vendor_id = defaultVendorId;
            requestBody.mounting_type = mountingType;
            requestBody.height = parseFloat(requestBody.height.toString());
            requestBody.sectors = sectorList;
            requestBody.venue_id = associatedVenue ? associatedVenue.id : null;
            requestBody.building_id = selectedBuilding ? selectedBuilding.id : null;
            requestBody.floor_id = selectedFloor ? selectedFloor.id : null;
            requestBody.deployment_location = associatedVenue ? 'indoor' : 'outdoor';
            requestBody.technology_type = 'cbrs_5g';

            ApiHandler({ apiName: 'addAsset', body: requestBody, params: {} })
              .then((response: any) => {
                setLoading(false);
                goBack('home');
              })
              .catch((error: any) => {
                setLoading(false);
                if (error.doLogout === true) {
                  setError({ message: 'Session Timed Out', alert: true });
                  setTimeout(() => navigate('/login', { replace: true }), 2000);
                } else {
                  setError({ message: error.data.message, alert: true });
                }
              });
          })
          .catch((error: any) => {
            setLoading(false);
            setError({
              message: error.response.data.features[0].properties.statusMessage,
              alert: true,
            });
          });
      })
      .catch((error: any) => {
        setLoading(false);
        setError({
          message: error?.response?.data?.features[0]?.properties?.statusMessage ?? 'Elevation Calculation Failed.',
          alert: true,
        });
      });
  };

  const addSectorToRequest = (sectorAzimuth: string, sectorID: number, haat: string): AddSectorProps => {
    let sector: any = DEFAULT_SECTOR;
    sector.sector_id = sectorID.toString();
    sector.tracking_id = asset.tracking_id;
    sector.height = parseFloat((parseFloat(asset.height.toString() || '0') / 3.281).toFixed(2));
    sector.azimuth = parseFloat(sectorAzimuth);
    sector.haat = haat;
    return sector;
  };

  const calculateElevation = () => {
    let requestParams = {
      lat: parseFloat(position.lat.toFixed(6)),
      lon: parseFloat(position.lng.toFixed(6)),
      src: 'ned_1',
      unit: 'm',
    };
    return axios.get('https://geo.fcc.gov/api/contours/elevation.json', {
      params: requestParams,
    });
  };

  const calculateHaat = (rcamsl: number) => {
    let requestParams = {
      lat: parseFloat(position.lat.toFixed(6)),
      lon: parseFloat(position.lng.toFixed(6)),
      nradial: 8,
      rcamsl: rcamsl,
      src: 'ned_1',
      unit: 'm',
    };
    return axios.get('https://geo.fcc.gov/api/contours/haat.json', {
      params: requestParams,
    });
  };

  const handleInputChange = (e: any) => {
    setAsset((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const startIncreaseAzimuth = () => {
    setAzimuthAngle((azimuthAngle) => (azimuthAngle == 359 ? 0 : azimuthAngle + 1));
    azimuthInterval.current = setInterval(() => {
      setAzimuthAngle((azimuthAngle) => (azimuthAngle == 359 ? 0 : azimuthAngle + 1));
    }, 100);
  };

  const startDecreaseAzimuth = () => {
    setAzimuthAngle((azimuthAngle) => (azimuthAngle == 0 ? 359 : azimuthAngle - 1));
    azimuthInterval.current = setInterval(() => {
      setAzimuthAngle((azimuthAngle) => (azimuthAngle == 0 ? 359 : azimuthAngle - 1));
    }, 100);
  };

  const stopAzimuthRotation = () => {
    clearInterval(azimuthInterval.current);
  };

  const confirmAzimuth = () => {
    if (mountingType === CBSDMountingTypes.ROE) {
      setAzimuth([azimuthAngle.toString()]);
    } else if (mountingType === CBSDMountingTypes.AERIAL) {
      let s1 = azimuthAngle + 225;
      let s2 = azimuthAngle + 45;
      if (s1 >= 360) s1 = s1 - 360;
      if (s2 >= 360) s2 = s2 - 360;
      setAzimuth([s1.toString(), s2.toString()]);
    }
    cancelAzimuth();
  };

  const cancelAzimuth = () => {
    azimuthActivity(false);
    activateAzimuth(false);
  };

  const initiateAssociationWithVenue = () => {
    setVenueId(associatedVenue?.id);
    listBuildings();
  };

  const listBuildings = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'listBuildings',
      body: {},
      params: { venue_id: associatedVenue?.id },
    })
      .then((res) => {
        setLoading(false);
        setAvailableBuildings(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listFloors = () => {
    setLoading(true);
    ApiHandler({
      apiName: 'listFloors',
      body: {},
      params: { building_id: selectedBuilding?.id },
    })
      .then((res) => {
        setLoading(false);
        setAvailableFloors(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const showFloorPlan = (floorId: any) => {
    unsetFloorGeoJSON();
    if (floorId === null) return;
    setLoading(true);
    ApiHandler({
      apiName: 'getFloorGeoJson',
      body: {},
      params: { floor_id: floorId },
    })
      .then((res) => {
        setLoading(false);
        setFloorGeoJSONUrl(res.data.url);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleAlertForImages = (alertType: 'error' | 'warning' | 'success', message: string) => {
    if (alertType === 'error') {
      setError({ message: message, alert: true });
    } else if (alertType === 'warning') {
      setWarning({ message: message, alert: true });
    } else if (alertType === 'success') {
      setSuccess({ message: message, alert: true });
    }
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              goBack('home');
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">ADDING CBSD</span>
        </div>

        <span className="asset-drop-info mt-2 mb-2 w-100">Click where you'd like to drop the CBSD</span>

        <form className="overflow-auto pe-3 ps-1 pb-1 mb-1 pt-2">
          {getOrganisationId() !== 1 ? (
            <div className="p-2 mb-2 venue-association-box col-wise">
              {associatedVenue === null ? (
                <label className="mb-2 ms-2 association-info">Drop the location pin inside a venue to initiate association.</label>
              ) : (
                <span className="indoor-sub-heading mb-2 ms-2">
                  Venue: <span className="indoor-value">{associatedVenue.name}</span>
                </span>
              )}

              {/* Buildings dropdown */}
              <span className="indoor-sub-heading ms-2">Building</span>
              <div className="dropdown mb-2">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedBuilding ? selectedBuilding.name : 'Select Building (optional)'}
                </button>

                <ul className="dropdown-menu w-100">
                  {availableBuildings.map((building: any) => {
                    return (
                      <li key={building.id}>
                        <span
                          onClick={() => {
                            setSelectedBuilding(building);
                            setBuildingId(building.id);
                          }}
                          className="dropdown-item"
                        >
                          {building.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Floor dropdown */}
              <span className="indoor-sub-heading ms-2">Floor</span>
              <div className="dropdown">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedFloor ? selectedFloor.floor_number : 'Select Floor (optional)'}
                </button>

                <ul className="dropdown-menu w-100">
                  {availableFloors.map((floor: any) => {
                    return (
                      <li key={floor?.id}>
                        <span
                          onClick={() => {
                            setSelectedFloor(floor);
                            setFloorId(floor?.id);
                            showFloorPlan(floor?.id);
                          }}
                          className="dropdown-item"
                        >
                          {floor?.floor_number}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}

          <LabelledInput
            title={getOrganisationId() !== 1 || vendorType !== 'Nokia' ? 'Tracking ID*' : 'UID*'}
            name="tracking_id"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.tracking_id}
          />
          <LabelledInput
            title="CBRS Node Name"
            name="cbrs_node_name"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.cbrs_node_name}
          />
          <LabelledInput
            title="Site ID"
            name="site_id"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.site_id}
          />
          <LabelLessDropdown
            className="mb-2"
            title="Mounting Type*"
            options={MOUNTING_TYPES}
            fullWidth
            onChange={(e: any) => {
              setMountingType(e.target.value);
            }}
            value={mountingType}
          />
          <div className="form-floating w-100 mb-2">
            <textarea
              rows={5}
              id="description"
              className="form-control textbox-box input-text h-auto"
              placeholder={'Description'}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={asset.description}
            ></textarea>
            <label className="input-label" htmlFor="description">
              Description
            </label>
          </div>
          <div
            className="image-box p-2 mb-2 col-wise cursor-pointer"
            onClick={() => {
              initiateImageUpload();
            }}
          >
            <span className="input-label mb-2">Images</span>
            <span>{`${assetImages?.filter((image: IAssetImageObj) => image.uploaded).length}/10 uploaded`}</span>
          </div>
          <LabelledInput title="Vendor Type" disabled value={directionality} />
          <div className="row-wise w-100 justify-content-evenly mb-2">{getSectorIcons().map((icon: any) => icon)}</div>
          <LabelledInput
            title="Antenna Height (ft)*"
            type="number"
            name="height"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.height}
          />
          <LabelledInput
            title="Serial #*"
            name="serial_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.serial_number}
          />
          <LabelledInput
            title="MAC"
            name="device_mac"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.device_mac}
          />
          <LabelledInput
            title="P/N"
            name="device_pn"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.device_pn}
          />
          <LabelledInput
            title="Docsis Serial #"
            name="cm_serial_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.cm_serial_number}
          />
          <LabelledInput
            title="Docsis Mac"
            name="cm_mac"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.cm_mac}
          />
          <LabelledInput
            title="Docsis P/N"
            name="cm_pn"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={asset.cm_pn}
          />
          <LabelledInput title="Latitude*" disabled value={position.lat.toFixed(6)} />
          <LabelledInput title="Longitude*" disabled value={position.lng.toFixed(6)} />

          {getSectors().map((sector: any) => sector)}
          {directionality === 'Directional' && (
            <SecondaryButton
              className="mb-2"
              label="Set Azimuth Value"
              clicked={() => {
                azimuthActivity(true);
                activateAzimuth(true);
                setAzimuthAngle(0);
              }}
              disable={false}
            />
          )}
        </form>
        <PrimaryButton className="mb-2 me-4 ms-1 mt-2" label="Add CBSD" clicked={addAsset} disable={false} />
      </div>

      {/* Azimuth Image - rotates on button click */}
      <div className={'position-fixed azimuth-icon-position ' + (isAzimuthActive ? '' : 'd-none')}>
        <img src={mountingType === CBSDMountingTypes.AERIAL ? AerialIcon : RoeIcon} style={{ transform: 'rotate(' + azimuthAngle + 'deg)' }}></img>
      </div>

      {/* Azimuth action buttons */}
      <div
        className={'position-fixed ' + (isAzimuthActive ? '' : 'd-none')}
        style={{
          top: 'calc(50% + 22px)',
          right: '100px',
          transform: 'translate(0px, -50%)',
        }}
      >
        <div className="d-flex align-items-center col-wise">
          <div onClick={confirmAzimuth} className="center confirm-azimuth mb-4">
            <span className="material-icons-round">done</span>
          </div>

          <div className="col-wise">
            <div className="center plus-icon" onMouseDown={startIncreaseAzimuth} onMouseUp={stopAzimuthRotation} onMouseLeave={stopAzimuthRotation}>
              <span className="material-icons-round">add</span>
            </div>

            <div className="center azimuth-label">
              <label>{azimuthAngle} N</label>
            </div>

            <div className="center minus-icon" onMouseDown={startDecreaseAzimuth} onMouseUp={stopAzimuthRotation} onMouseLeave={stopAzimuthRotation}>
              <span className="material-icons-round">remove</span>
            </div>
          </div>

          <div onClick={cancelAzimuth} className="center reset-azimuth mt-4">
            <span className="material-icons-round">restart_alt</span>
          </div>
        </div>
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={`Vendor for ${mountingType} is not selected. Set it in your profile.`}
        primaryText={'Take me there'}
        secondaryText={"I'll do it later"}
        secondaryAction={() => {
          showVendorNotSelected(false);
        }}
        visible={vendorNotSelected}
        title={'Vendor not selected'}
        primaryAction={() => {
          goToProfile();
        }}
      />

      <ConfirmAlert
        message={`You have moved the location pin in the <b>${associatedVenue?.name}</b> venue.`}
        note="Asset will be associated to this venue."
        primaryText={'OK'}
        secondaryText={null}
        secondaryAction={() => {
          setAssetOnVenueAlert(false);
          setAssociatedVenue(null);
        }}
        visible={assetOnVenueAlert}
        title={'Location landed in venue'}
        primaryAction={() => {
          setAssetOnVenueAlert(false);
          initiateAssociationWithVenue();
        }}
      />

      <AssetImagesModal
        images={assetImages ?? []}
        actionType="add"
        show={showImageModal}
        close={() => {
          setShowImageModal(false);
        }}
        setLoading={setLoading}
        trackingId={asset.tracking_id}
        handleChange={(value: IAssetImageObj[]) => {
          setAssetImages([...value]);
        }}
        alert={(alertType: 'error' | 'warning' | 'success', message: string) => {
          handleAlertForImages(alertType, message);
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};

export default Asset;
